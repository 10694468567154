import React, { PureComponent } from 'react';
import { bool, func, number, oneOfType, shape, string, element } from 'prop-types';
import { pick, prop, propOr } from 'ramda';
export const availableInputAttributs = [
  'type',
  'name',
  'value',
  'onBlur',
  'checked',
  'disabled',
  'onChange',
  'onBlur',
  'onKeyUp',
  'onFocus',
  'readOnly',
  'autofocus',
  'autocomplete',
  'defaultValue',
  'placeholder',
  'onEnter'
];
export const inputPropTypesObject = {
  type: string,
  name: string,
  value: oneOfType([string, bool, number]),
  checked: bool,
  disabled: bool,
  onChange: func,
  onFocus: func,
  onBlur: func,
  readOnly: bool,
  autofocus: bool,
  autocomplete: string,
  defaultValue: oneOfType([string, bool, number]),
  placeholder: string,
  onEnter: func
};
export const inputPropTypes = shape(inputPropTypesObject);
export const getInputProps = props => pick(availableInputAttributs, props);
const makeInputProps = props => {
  const inputProps = { ...getInputProps(props), ...propOr({}, 'input', props) };
  const type = propOr('field', 'type', inputProps);
  const name = prop('name', inputProps);
  return {
    ...inputProps,
    'data-cy': prop('data-cy', props) || `${type}-${name}`
  };
};
export const formatInputProps = () => WrappedComponent => {
  class Input extends PureComponent {
    state = { isFetching: false };
    static defaultProps = {
      input: {},
      placeholder: '',
      type: 'text',
      onFetch: false,
      debounce: false
    };
    static propTypes = {
      input: inputPropTypes,
      onFetch: oneOfType([bool, func]),
      debounce: oneOfType([bool, func]),
      onBlur: func,
      onKeyUp: func,
      forwardRef: oneOfType([func, shape({ current: element })])
    };
    addFetchMethod() {
      this.inputProps = {
        ...this.inputProps,
        onBlur: this.props.onBlur ? this.props.onBlur : () => this.runFetchMethod(),
        onKeyUp: this.props.onKeyUp
          ? this.props.onKeyUp
          : event => (event.keyCode === 13 ? this.runFetchMethod() : null)
      };
    }
    async runFetchMethod() {
      this.setState({ isFetching: true });
      try {
        await this.props.onFetch();
      } catch (e) {}
      this.setState({ isFetching: false });
    }
    addDebounce() {
      this.inputProps = {
        ...this.inputProps,
        onChange: event => {
          this.setState({ isFetching: true });
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
          const value = event.target.value;
          this.debounceTimeout = setTimeout(() => {
            this.props.debounce(value);
            this.setState({ isFetching: false });
          }, 500);
        }
      };
    }
    componentWillUnmount() {
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
    }
    render() {
      const { debounce, forwardRef } = this.props;
      const { isFetching } = this.state;
      this.inputProps = makeInputProps(this.props);
      this.addFetchMethod();
      if (debounce) this.addDebounce();
      return <WrappedComponent ref={forwardRef} pending={isFetching} inputProps={this.inputProps} {...this.props} />;
    }
  }
  return React.forwardRef((props, ref) => <Input {...props} forwardRef={ref} />);
};
