import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const ChevronDown = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.663 8.771a1.138 1.138 0 00-1.605 0l-4.086 4.03-4.03-4.03a1.138 1.138 0 10-1.605 1.616l4.826 4.827a1.138 1.138 0 001.617 0l4.883-4.827a1.138 1.138 0 000-1.616z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default ChevronDown;
